<template>
  <div>
    <div
      class="py-14 lg:py-16"
      :style="{
        backgroundColor: lgAndUp
          ? data?.backgroundColor?.hexa || '#333845'
          : data?.backgroundColorMobile?.hexa ||
            data?.backgroundColor?.hexa ||
            '#333845',
      }"
    >
      <div class="container mb-8.5 lg:mb-10.5 flex items-center justify-between">
        <div
          class="w-full flex flex-col justify-center gap-6 text-left lg:text-center"
        >
          <div
            v-if="data?.title"
            class="font-manrope text-xl font-semibold capitalize text-aquamarine-900 lg:text-[24px]"
          >
            <span>{{ data.title }}</span>
          </div>
          <h2
            v-if="data?.subTitle"
            class="text-[40px] lg:text-[56]"
          >
            <span>{{ data.subTitle }}</span>
          </h2>
        </div>
        <div v-if="!hideArrows" class="shrink-0 max-lg:hidden">
          <button
            ref="prevEl"
            :aria-label="$t('previousSlide')"
            class="mr-3 rounded-full transition-all hover:bg-gray-strokes/50"
          >
            <UiIcon name="chevron_left_thin_s" class="h-11 w-11 text-primary" />
          </button>
          <button
            ref="nextEl"
            :aria-label="$t('nextSlide')"
            class="rounded-full transition-all hover:bg-gray-strokes/50"
          >
            <UiIcon
              name="chevron_right_thin_s"
              class="h-11 w-11 text-primary"
            />
          </button>
        </div>
      </div>
      <UiScrollableSlider
        :items-list="data.slides || []"
        :slides-count="data?.slidesCount?.mobile || 2"
        :item-classes="'h-auto'"
        class="container px-0 lg:!hidden"
        :gap-x="16"
      >
        <template #card="{ item }">
          <div
            class="flex items-stretch h-full"
            :style="{
              backgroundColor: item.backgroundColorMobile?.hexa || '#F6F6F6',
            }"
          >
            <div v-html="item?.text"></div>
          </div>
        </template>
      </UiScrollableSlider>
      <div class="flex items-center gap-x-4 lg:container max-lg:hidden">
        <Swiper
          :slides-per-view="data?.slidesCount?.mobile || 2"
          :space-between="0"
          :loop="false"
          :centered-slides="false"
          :initial-slide="0"
          :modules="modules"
          :navigation="navigation"
          class="w-full"
          :breakpoints="{
            [screens.md]: {
              slidesPerView: data?.slidesCount?.desktop || 3,
              centeredSlides: false,
              initialSlide: 0,
              spaceBetween: 30,
            },
          }"
          @swiper="initSwiper"
          @resize="onUpdateSwiper"
        >
          <SwiperSlide
            v-for="item in data.slides?.slice(0, 15)"
            :key="item.id"
            class="max-lg:px-2.5"
            style="height: auto"
          >
            <div
              class="relative h-full"
              :style="{
                backgroundColor: item.backgroundColor?.hexa || '#F6F6F6',
              }"
            >
              <div class="h-full" v-html="item?.text"></div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div
        v-if="data.buttonUrl"
        class="w-full flex justify-center items-center pt-10 lg:pt-12.5"
      >
        <UiButton :to="localePath(data.buttonUrl)" size="md">
          {{ data.buttonLabel }}
        </UiButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper";
import { NavigationOptions } from "swiper/types";
import { debounce } from "lodash-es";

import { BlockSliderContent } from "ecom-types";
import { screens } from "~/configs/breakpointsConfig.json";

const { lgAndDown, lgAndUp } = useAppBreakpoints();
const localePath = useLocalePathPolyfill();

const props = defineProps<{ data: BlockSliderContent }>();

const hideArrows = ref(false);

const prevEl = ref<HTMLElement | null>(null);
const nextEl = ref<HTMLElement | null>(null);

const debounceSwiper = debounce((params) => {
  hideArrows.value =
    lgAndDown.value ||
    params?.params?.slidesPerView >= (props.data?.slides?.length || 2);
}, 200);

function initSwiper(params: any) {
  debounceSwiper(params);
}

function onUpdateSwiper(params: any) {
  debounceSwiper(params);
}

const navigation = reactive({
  prevEl,
  nextEl,
  disabledClass: "opacity-20 cursor-not-allowed",
}) as NavigationOptions;

const modules = [Navigation];
</script>
